import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../Modal"
import LearnMore from "../LearnMore"

import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const ClimateSolutions = ({ backgroundImage, card1Image, card2Image }) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false)
    } else {
      setShowModal(showModal)
    }
  }
  const image = convertToBgImage(getImage(backgroundImage))
  return (
    <>
      <BackgroundImage {...image} className="climate-solutions-background">
        <section className="climate-solutions">
          <div className="container">
            <div className="climate-solutions__card">
              <h2>Climate Solution</h2>
              <h3>Risk Management</h3>
              <p>
                Understand your physical climate risk with property and
                portfolio analysis.
              </p>
              <a
                className="link-btn"
                onClick={() =>
                  setShowModal({
                    modalHeading: "Get a Free Sample",
                    initialMessage: "I'd like to get a free sample",
                  })
                }
              >
                Get a Free Sample
                <CTARightArrow />
              </a>
              <GatsbyImage
                image={card1Image.childImageSharp.gatsbyImageData}
                alt="An image of documents about risk management"
              />
            </div>
            <div className="climate-solutions__card">
              <h2>Climate Solution</h2>
              <h3>Regulatory Disclosure</h3>
              <p>
                Follow best practices and stay ahead of evolving regulatory
                requirements.
              </p>
              <a
                className="link-btn"
                onClick={() =>
                  setShowModal({
                    modalHeading: "Learn How We Can Help",
                    initialMessage:
                      "I'd like to learn more about regulatory disclosure",
                  })
                }
              >
                Learn How We Can Help
                <CTARightArrow />
              </a>
              <GatsbyImage
                image={card2Image.childImageSharp.gatsbyImageData}
                alt="Logos of task force climate-related financial disclosures, u.s. securities and exchange commission, and canadian securities exchange"
              />
            </div>
          </div>
        </section>
      </BackgroundImage>
      {showModal && (
        <Modal onClose={showModal} setOpen={() => setShowModal(!showModal)}>
          <LearnMore
            toggle={toggleModal}
            initialMessage={showModal.initialMessage}
            heading={showModal.modalHeading}
          />
        </Modal>
      )}
    </>
  )
}

export default ClimateSolutions
