import React, { useState } from "react"

import Modal from "../Modal"
import LearnMore from "../LearnMore"

import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const TopQualityData = () => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }
  return (
    <>
      <section className="top-quality-data">
        <div className="container">
          <h2>Top-quality data delivered the way you need it</h2>
          <div className="top-quality-data__cards">
            <div className="top-quality-data__card">
              <h3>Reliable</h3>
              <p>
                ClimateCheck provides you with a crystal-clear picture of
                climate risk in the United States and Canada.
              </p>
              <p>
                We take complex climate data and boil it down to an actionable
                1-100 score for hazards like extreme heat and precipitation,
                wildfire, drought, wind, and flood. 1 means a property or region
                is exposed to relatively low risk. 100 means extreme risk.
              </p>
            </div>
            <div className="top-quality-data__card">
              <h3>Complete</h3>
              <p>
                Our team of PhD scientists interpret dozens of internationally
                accepted global climate datasets to provide the most accurate
                climate risk ratings available today — risk ratings you can
                trust when making important decisions.
              </p>
            </div>
            <div className="top-quality-data__card">
              <h3>Actionable</h3>
              <p>
                Our easy-to-access real estate climate risk data sets present
                current risk exposure and provide you with projections for up to
                40 years in the future. Better understand the climate risk of an
                individual property, a portfolio of properties, or any
                geographic area.
              </p>
            </div>
          </div>
          <a className="link-btn" onClick={() => setShowModal(true)}>
            Explore Our Data
            <CTARightArrow />
          </a>
        </div>
      </section>
      {showModal && (
        <Modal onClose={showModal} setOpen={() => setShowModal(!showModal)}>
          <LearnMore
            toggle={toggleModal}
            initialMessage="I'd like to know more about your data"
            heading="Explore Our Data"
          />
        </Modal>
      )}
    </>
  )
}

export default TopQualityData
