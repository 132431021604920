import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const OurCustomers = ({
  card1Image,
  card2Image,
  card3Image,
  card4Image,
  card5Image,
  card6Image,
}) => {
  return (
    <section className="our-customers">
      <div className="container">
        <h2>Our Customers</h2>
        <div className="our-customers__cards">
          <div className="our-customers__card">
            <GatsbyImage
              image={card1Image.childImageSharp.gatsbyImageData}
              alt="skyscrapers with a river in the middle of them"
            />
            <div className="content">
              <h3>Real Estate Investors</h3>
              <p>
                Add climate risk data into your investment thesis and stay ahead
                of evolving regulation.
              </p>
              {/* <div className="link">
                <Link to="/" className="link-btn">
                  Learn more
                  <CTARightArrow />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="our-customers__card">
            <GatsbyImage
              image={card2Image.childImageSharp.gatsbyImageData}
              alt="skyscrapers with a river in the middle of them"
            />
            <div className="content">
              <h3>Lenders</h3>
              <p>
                Issue new loans with climate risk screening and analyze existing
                portfolio for current and future climate risks.
              </p>
              {/* <div className="link">
                <Link to="/" className="link-btn">
                  Learn more
                  <CTARightArrow />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="our-customers__card">
            <GatsbyImage
              image={card3Image.childImageSharp.gatsbyImageData}
              alt="skyscrapers with a river in the middle of them"
            />
            <div className="content">
              <h3>Data & Analytics</h3>
              <p>
                Add climate risk to offer your customers a more complete
                understanding of the risks to their assets.
              </p>
              {/* <div className="link">
                <Link to="/" className="link-btn">
                  Learn more
                  <CTARightArrow />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="our-customers__card">
            <GatsbyImage
              image={card4Image.childImageSharp.gatsbyImageData}
              alt="skyscrapers with a river in the middle of them"
            />
            <div className="content">
              <h3>Due Diligence Consultants</h3>
              <p>
                Include climate risk in your client’s Property Condition
                Assessment (PCA) and Environmental Phase I Reports.
              </p>
              {/* <div className="link">
                <Link to="/" className="link-btn">
                  Learn more
                  <CTARightArrow />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="our-customers__card">
            <GatsbyImage
              image={card5Image.childImageSharp.gatsbyImageData}
              alt="skyscrapers with a river in the middle of them"
            />
            <div className="content">
              <h3>Asset Managers</h3>
              <p>
                Understand, manage, and mitigate climate risks for your asset
                portfolios to stay ahead of evolving regulation.
              </p>
              {/* <div className="link">
                <Link to="/" className="link-btn">
                  Learn more
                  <CTARightArrow />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="our-customers__card">
            <GatsbyImage
              image={card6Image.childImageSharp.gatsbyImageData}
              alt="skyscrapers with a river in the middle of them"
            />
            <div className="content">
              <h3>ESG Consultants</h3>
              <p>
                Help your clients manage, mitigate, and identify opportunities
                related to climate risk for all their assets.
              </p>
              {/* <div className="link">
                <Link to="/" className="link-btn">
                  Learn more
                  <CTARightArrow />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurCustomers
