import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import Redfin from "@images/img-min/redfin.svg"
import Attom from "@images/img-min/attom.svg"
import Mornigstar from "@images/img-min/morningstar.svg"
import Movoto from "@images/img-min/movoto.svg"
import LocalLogic from "@images/img-min/locallogic.svg"
import Cretelligent from "@images/img-min/cretelligent.svg"
import Homescout from "@images/img-min/homescout.svg"
import Cherre from "@images/img-min/cherre.svg"
import GreenStreet from "@images/img-min/greenstreet.svg"
import Liveby from "@images/img-min/liveby.svg"
import Estately from "@images/img-min/estately.svg"
import Safegraph from "@images/img-min/safegraph.svg"
import { Link } from "gatsby"
import Modal from "../Modal"
import LearnMore from "../LearnMore"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const Hero = ({ herobg }) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const image = convertToBgImage(
    getImage(herobg.childImageSharp.gatsbyImageData)
  )
  return (
    <>
      <BackgroundImage {...image}>
        <section className="solutions-hero">
          <div className="container">
            <h1>Complete and Reliable Climate Risk Data and Solutions</h1>
            <p>
              Enterprise climate risk data solutions for real estate investors,
              environmental consultants, lenders, insurance companies, and
              analytics providers.
            </p>
            <a className="link-btn" onClick={() => setShowModal(true)}>
              Request a Demo
              <CTARightArrow />
            </a>
          </div>
        </section>
      </BackgroundImage>
      {showModal && (
        <Modal onClose={showModal} setOpen={() => setShowModal(!showModal)}>
          <LearnMore
            toggle={toggleModal}
            initialMessage="I'd like to request a demo"
            heading="Request a Demo"
          />
        </Modal>
      )}
    </>
  )
}

export default Hero
