import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Modal from "../Modal"
import LearnMore from "../LearnMore"

import ClimateRiskScreening from "@images/img/climateRiskScreening.svg"
import PropertyLevelReports from "@images/img/propertyLevelReports.svg"
import PortfolioAnalysis from "@images/img/portfolioAnalysis.svg"
import DataFeed from "@images/img/dataFeed.svg"
import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const ClimateSolutionProducts = () => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false)
    } else {
      setShowModal(showModal)
    }
  }
  return (
    <>
      <section className="climate-solution-products">
        <div className="container">
          <h2>Climate Solution Products</h2>
          <div className="climate-solution-products__cards">
            <div className="climate-solution-products__card">
              <ClimateRiskScreening />
              <h3>Climate Risk Screening</h3>
              <p>A quick overview of the hazards related to your assets.</p>
              <div className="inner-card">
                <h4>What is it used for?</h4>
                <ul>
                  <li>Inform your underwriting and due diligence.</li>
                  <li>
                    Quickly understand an individual property’s exposure to
                    climate risks.
                  </li>
                </ul>
              </div>
              <div className="link">
                <a
                  className="link-btn"
                  onClick={() =>
                    setShowModal({
                      modalHeading: "Order Snapshot",
                      initialMessage: "I'd like to order a snapshot",
                    })
                  }
                >
                  Order Snapshot
                  <CTARightArrow />
                </a>
              </div>
            </div>
            <div className="climate-solution-products__card">
              <PropertyLevelReports />
              <h3>Property Level Reports</h3>
              <p>Detailed 35-page property level report.</p>
              <div className="inner-card">
                <h4>What is it used for?</h4>
                <ul>
                  <li>Comprehensive due diligence for new acquisitions.</li>
                  <li>Analyze and monitor existing assets.</li>
                  <li>
                    Deep dive on individual property risk with hazard maps for
                    geographic comparison, a granular timeline of historical to
                    future risk, and easy to understand data visualizations.
                  </li>
                </ul>
              </div>
              <div className="link">
                <a
                  className="link-btn"
                  onClick={() =>
                    setShowModal({
                      modalHeading: "Order Report",
                      initialMessage: "I'd like to order a report",
                    })
                  }
                >
                  Order Report
                  <CTARightArrow />
                </a>
              </div>
            </div>
            <div className="climate-solution-products__card">
              <PortfolioAnalysis />
              <h3>Portfolio Analysis</h3>
              <p>A high-level climate risk analysis on a portfolio of assets</p>
              <div className="inner-card">
                <h4>What is it used for?</h4>
                <ul>
                  <li>
                    Analyze and monitor your portfolios for exposure to climate
                    risk.{" "}
                  </li>
                  <li>
                    Create actionable steps for diversification and mitigation.
                  </li>
                  <li>
                    Inform your investment thesis to minimize risk and maximize
                    opportunities in a changing environment.
                  </li>
                  <li>
                    Designed for everyone in the capital stack including
                    lenders, investors, corporations, retail and commercial
                    lease holders.
                  </li>
                </ul>
              </div>
              <div className="link">
                <a
                  className="link-btn"
                  onClick={() =>
                    setShowModal({
                      modalHeading: "Order a Portfolio Analysis",
                      initialMessage: "I'd like to order a portfolio analysis",
                    })
                  }
                >
                  Order a Portfolio Analysis
                  <CTARightArrow />
                </a>
              </div>
            </div>
            <div className="climate-solution-products__card">
              <DataFeed />
              <h3>Data Feed</h3>
              <p>
                Climate risk data for all hazards delivered in bulk or via API
              </p>
              <div className="inner-card">
                <h4>What is it used for?</h4>
                <ul>
                  <li>
                    Custom data queries for all hazards across North America.
                  </li>
                  <li>Granular historical, current, and future risk.</li>
                  <li>
                    Instantly assess risk, whether for an individual property,
                    multiple properties, or entire regions – from corridors and
                    cities to states, provinces, territories, zip, or postal
                    codes.
                  </li>
                  <li>
                    Unique bulk data product allows you to build derivative
                    products and integrate with existing data platforms.
                  </li>
                </ul>
              </div>
              <div className="link">
                <a
                  className="link-btn"
                  onClick={() =>
                    setShowModal({
                      modalHeading: "Request Data Sample",
                      initialMessage: "I'd like to request a data sample",
                    })
                  }
                >
                  Request Data Sample
                  <CTARightArrow />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <Modal onClose={showModal} setOpen={() => setShowModal(!showModal)}>
          <LearnMore
            toggle={toggleModal}
            initialMessage={showModal.initialMessage}
            heading={showModal.modalHeading}
          />
        </Modal>
      )}
    </>
  )
}

export default ClimateSolutionProducts
