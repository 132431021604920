import React from "react"
import { graphql } from "gatsby"

import SEO from "@components/SEO"
import CorporativeHeader from "@components/global/CorporativeHeader"
import Hero from "@components/Solutions/Hero"
import Partners from "@components/HomePage/Partners"
import TopQualityData from "@components/Solutions/TopQualityData"
import ClimateRisks from "@components/HomePage/ClimateRisks"
import ClimateSolutions from "@components/Solutions/ClimateSolutions"
import ClimateSolutionProducts from "@components/Solutions/ClimateSolutionProducts"
import OurCustomers from "@components/Solutions/OurCustomers"
import RiskAssessment from "@components/global/RiskAssessment"
import Footer from "@components/global/Footer"
import "@styles/Components/solutions.scss"

import CorporativeFooter from "@components/global/CorporativeFooter"
import ClimateRiskData from "@components/Solutions/ClimateRiskData"
import DataDelivery from "@components/Solutions/DataDelivery"
import AssetLevel from "@components/Solutions/AssetLevel"
import Portfolio from "@components/Solutions/Portfolio"
import AreaCorridor from "@components/Solutions/AreaCorridor"
import LearnMore from "@components/Solutions/LearnMore"
import GetInstantRiskAssessment from "@components/Solutions/GetInstanceRiskAssessment/GetInstantRiskAssessment"

const Solutions = ({
  data: {
    seo,
    herobg,
    climateRisksCard1Image,
    climateRisksCard2Image,
    climateRisksCard3Image,
    climateRisksCard4Image,
    climateRisksCard5Image,
    climateRisksCard6Image,
    climateRisksCard7Image,
    climateRisksCard8Image,
    climateRisksCard9Image,
    climateRisksCard10Image,
    climateSolutionsBackgroundImage,
    climateSolutionsCard1,
    climateSolutionsCard2,
    ourCustomersCard1Image,
    ourCustomersCard2Image,
    ourCustomersCard3Image,
    ourCustomersCard4Image,
    ourCustomersCard5Image,
    ourCustomersCard6Image,
    mapone,
    maptwo,
    icon1,
    icon2,
    icon3,
    screenshot,
    browserImg,
    dataDeliveryImg,
    assetLevelImg,
    portfolioImgOne,
    portfolioImgTwo,
    areaReports,
    corridorsReports,
    areaCorridorRight,
  },
}) => {
  const customSeo = {
    title: seo?.seo?.title || "Solutions",
    link: "https://climatecheck.com/solutions",
    ...seo?.seo,
  }

  const icons = {
    icon1,
    icon2,
    icon3,
  }
  return (
    <>
      <SEO seo={customSeo} />
      <CorporativeHeader inSolutions={true} />
      <Hero herobg={herobg} />
      <Partners />
      <TopQualityData />
      <ClimateRisks
        card1Image={climateRisksCard1Image}
        card2Image={climateRisksCard2Image}
        card3Image={climateRisksCard3Image}
        card4Image={climateRisksCard4Image}
        card5Image={climateRisksCard5Image}
        card6Image={climateRisksCard6Image}
        card7Image={climateRisksCard7Image}
        card8Image={climateRisksCard8Image}
        card9Image={climateRisksCard9Image}
        card10Image={climateRisksCard10Image}
      />
      <ClimateSolutions
        backgroundImage={climateSolutionsBackgroundImage}
        card1Image={climateSolutionsCard1}
        card2Image={climateSolutionsCard2}
      />
      <ClimateSolutionProducts />
      <OurCustomers
        card1Image={ourCustomersCard1Image}
        card2Image={ourCustomersCard2Image}
        card3Image={ourCustomersCard3Image}
        card4Image={ourCustomersCard4Image}
        card5Image={ourCustomersCard5Image}
        card6Image={ourCustomersCard6Image}
      />
      <RiskAssessment />
      <Footer />
    </>
  )
}

export default Solutions

export const query = graphql`
  query SolutionsQuery {
    seo: sanityPagesSeo(slug: { current: { eq: "/solutions" } }) {
      seo {
        title
        keywords
        description
      }
    }
    herobg: file(relativePath: { eq: "img/solutions-hero-background.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard1Image: file(
      relativePath: { eq: "img/climateRisks-precipitation.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard2Image: file(
      relativePath: { eq: "img/climateRisks-drought.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard3Image: file(
      relativePath: { eq: "img/climateRisks-extreme-heat.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard4Image: file(
      relativePath: { eq: "img/climateRisks-wildfire.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard5Image: file(
      relativePath: { eq: "img/climateRisks-seismic.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard6Image: file(
      relativePath: { eq: "img/climateRisks-extreme-wind.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard7Image: file(
      relativePath: { eq: "img/climateRisks-flood-storm-surge.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard8Image: file(
      relativePath: { eq: "img/climateRisks-flood-sea-level-rise.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard9Image: file(
      relativePath: { eq: "img/climateRisks-flood-pluvial-fluvial.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard10Image: file(
      relativePath: { eq: "img/climateRisks-hurricane.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateSolutionsBackgroundImage: file(
      relativePath: { eq: "img/bg-topo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateSolutionsCard1: file(
      relativePath: { eq: "img/climateSolutions-risk-management.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateSolutionsCard2: file(
      relativePath: { eq: "img/climateSolutions-regulatory-disclosure.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourCustomersCard1Image: file(
      relativePath: { eq: "img/ourCustomers-card-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourCustomersCard2Image: file(
      relativePath: { eq: "img/ourCustomers-card-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourCustomersCard3Image: file(
      relativePath: { eq: "img/ourCustomers-card-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourCustomersCard4Image: file(
      relativePath: { eq: "img/ourCustomers-card-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourCustomersCard5Image: file(
      relativePath: { eq: "img/ourCustomers-card-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourCustomersCard6Image: file(
      relativePath: { eq: "img/ourCustomers-card-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    icon1: file(relativePath: { eq: "img/carbon_report-data-check.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    icon2: file(relativePath: { eq: "img/carbon_report-data-team.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    icon3: file(relativePath: { eq: "img/carbon_report-data-climate.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    screenshot: file(relativePath: { eq: "img/risk-iPad-Pro-11-Inch.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    mapone: file(relativePath: { eq: "img-min/image-00001.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    maptwo: file(relativePath: { eq: "img-min/image-00002.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    dataDeliveryImg: file(relativePath: { eq: "img/dataDelivery.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    browserImg: file(relativePath: { eq: "img/browser-img.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    assetLevelImg: file(relativePath: { eq: "img-min/Group-51.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    portfolioImgOne: file(relativePath: { eq: "img/portfolio_content.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    areaReports: file(relativePath: { eq: "img/area-reports.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    corridorsReports: file(relativePath: { eq: "img/corridor-reports.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    areaCorridorLeft: file(
      relativePath: { eq: "img-min/areacorridor-left.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    areaCorridorRight: file(
      relativePath: { eq: "img-min/areacorridor-right.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
  }
`
